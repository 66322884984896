import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  PartyRelationship,
  PartyRelationshipType,
  PartyRoleType,
} from '../../../data/model';
import {
  faCalendar,
  faSave,
  faTimes,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelationshipsService } from 'src/app/services/relationships.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { MembersService } from 'src/app/services/members.service';
import { OrganizationsService } from 'src/app/services/organizations.service';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-relationship-modal',
  templateUrl: './relationship-modal.component.html',
  styleUrl: './relationship-modal.component.scss',
})
export class RelationshipModalComponent {
  @Input() relationships: PartyRelationship[];
  @Input() relationship: PartyRelationship;
  @Input() relationshipsTypes: PartyRelationshipType[];
  @Input() partyRoleTypes: PartyRoleType[];
  @Input() personId: number;
  @Input() searchEntity: string;
  @Input() searchFor: string;
  @Input() limitToOne: boolean;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  selectedType: PartyRelationshipType;
  title: string;
  errorMsg = '';
  fromDateValue: NgbDate;
  thruDateValue: NgbDate;
  isEdit: boolean;
  maxDate: NgbDate | null = null;
  minDate: NgbDate | null = null;

  select: any = {};
  partyRelationshipTypeId: number;
  partyRoleTypeFromId: number;
  partyRoleTypeToId: number;

  listPartyRoleFrom: any = [];
  listPartyRoleTo: any = [];

  faCalendar = faCalendar;
  faSave = faSave;
  faTimes = faTimes;
  faSearch = faSearch;

  results: any = [];
  result: any;
  searchResults: any;
  prevRequests: any = [];

  constructor(
    private statusHelperService: StatusHelperService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal,
    private relationshipsService: RelationshipsService,
    private responseHelperService: ResponseHelperService,
    private membersService: MembersService,
    private organizationsService: OrganizationsService,
    private toastr: ToastrService
  ) {}

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.searchFor = this.searchFor;
    this.limitToOne = this.limitToOne;
    this.select = {};

    if (this.relationship) {
      this.title = 'Edit Party Relationship';
      this.partyRelationshipTypeId = this.relationship.partyRelationshipTypeId;
      this.getPartyRoleById(this.relationship.partyRoleFromId);
      this.getPartyRoleById(this.relationship.partyRoleToId, false);
      this.results = [this.relationship.partyIdTo];
      this.result = `${this.relationship.partyIdTo}`;
      this.fromDateValue = this.dateHelperService.stringToNgbDate(
        this.relationship.fromDate
      );
      this.thruDateValue = this.dateHelperService.stringToNgbDate(
        this.relationship.thruDate
      );
      this.getPartyRoleByPartyId(this.relationship.partyIdTo, false);
      this.isEdit = true;
    } else {
      this.title = 'New Party Relationship';
      this.relationship = new PartyRelationship();
      this.isEdit = false;
    }

    this.getPartyRoleByPartyId(this.personId);
  }

  getPartyRoleById(id: number, isFrom: boolean = true): void {
    this.relationshipsService.getPartyRoleById(id).subscribe(
      (data: any) => {
        if (data.success) {
          if (isFrom) {
            this.partyRoleTypeFromId = data.entity.partyRoleTypeId;
          } else {
            this.partyRoleTypeToId = data.entity.partyRoleTypeId;
          }
        }
      },
      () => {
        this.toastr.error('Error occured!');
      }
    );
  }

  getPartyRoleByPartyId(id: number, isFrom: boolean = true): void {
    this.relationshipsService.getPartyRoleByPartyId(id).subscribe(
      (data: any) => {
        if (data.success) {
          if (isFrom) {
            this.listPartyRoleFrom = data.entity;
          } else {
            this.listPartyRoleTo = data.entity;
          }
        }
      },
      () => {
        this.toastr.error('Error occured!');
      }
    );
  }

  clearDateInput(name: string): void {
    this[name] = null;
  }

  formatDate(name: string): void {
    switch (name) {
      case 'fromDate':
        if (this.fromDateValue !== null) {
          this.minDate = this.fromDateValue; // Set minDate for the "date to" picker
          if (
            this.fromDateValue &&
            this.isAfter(this.fromDateValue, this.maxDate)
          ) {
            this.fromDateValue = null; // Reset the "date to" if it's smaller than the new "minDate"
          }
          this.relationship.fromDate = this.dateHelperService.ngbDateToString(
            this.fromDateValue
          );
        } else {
          this.relationship.fromDate = null;
        }
        break;
      case 'thruDate':
        if (this.thruDateValue !== null) {
          this.maxDate = this.thruDateValue; // Set minDate for the "date to" picker
          if (
            this.thruDateValue &&
            this.isBefore(this.thruDateValue, this.minDate)
          ) {
            this.thruDateValue = null; // Reset the "date to" if it's smaller than the new "minDate"
          }
          this.relationship.thruDate = this.dateHelperService.ngbDateToString(
            this.thruDateValue
          );
        } else {
          this.relationship.thruDate = null;
        }
        break;
      default:
    }
  }

  isAfter(date1: NgbDate, date2: NgbDate): boolean {
    if (!date1 || !date2) {
      return false;
    }
    const d1 = new Date(date1.year, date1.month - 1, date1.day);
    const d2 = new Date(date2.year, date2.month - 1, date2.day);
    return d1 > d2;
  }

  // Helper function to check if date1 is before date2
  isBefore(date1: NgbDate, date2: NgbDate): boolean {
    if (!date1 || !date2) {
      return false;
    }
    const d1 = new Date(date1.year, date1.month - 1, date1.day);
    const d2 = new Date(date2.year, date2.month - 1, date2.day);
    return d1 < d2;
  }

  createPartyRoleIfNotExist(id: number, isFrom: boolean = true): void {
    if (isFrom) {
      const item = this.listPartyRoleFrom.find(
        (roleType) => parseInt(roleType.partyRoleTypeId) === +id
      );
      console.log({ item, prevRequests: this.prevRequests });
      if (item) {
        this.relationship.partyRoleFromId = item.id;
      } else {
        this.prevRequests.push(
          this.createOrUpdatePartyRole(id, this.relationship.partyIdFrom)
        );
      }
    } else {
      const item = this.listPartyRoleTo.find(
        (roleType) => parseInt(roleType.partyRoleTypeId) === +id
      );
      if (item) {
        this.relationship.partyRoleToId = item.id;
      } else {
        if (
          this.select.selectedSearch === 'ORGANIZATION' &&
          this.listPartyRoleTo.length > 0
        ) {
          const role = this.partyRoleTypes.find(
            (i) => +i.id === parseInt(this.listPartyRoleTo[0].partyRoleTypeId)
          );
          this.errorMsg = `The Organization has only one role and the existing is ${role?.roleName}!`;
          this.prevRequests = [];
          this.prevRequests.push(
            throwError(() => {
              return of(null);
            })
          );
          return;
        }

        // Push the creation request if no error
        this.prevRequests.push(
          this.createOrUpdatePartyRole(id, this.relationship.partyIdTo, false)
        );
      }
    }
  }

  createOrUpdatePartyRole(
    partyRoleTypeId: number,
    partyId: number,
    isFrom: boolean = true
  ): Observable<any> {
    return this.relationshipsService
      .createOrUpdatePartyRole(null, {
        partyRoleTypeId,
        partyId,
      })
      .pipe(
        tap((data: any) => {
          if (data.success) {
            if (isFrom) {
              this.relationship.partyRoleFromId = data.entity.id;
              this.getPartyRoleByPartyId(this.relationship.partyIdFrom);
            } else {
              this.relationship.partyRoleToId = data.entity.id;
              this.getPartyRoleByPartyId(this.relationship.partyIdTo, false);
            }
          }
        }),
        catchError((error) => {
          this.toastr.error('Error occurred!');
          return throwError(error);
        })
      );
  }

  createOrUpdatePartyRelationship(): void {
    this.relationshipsService
      .createOrUpdatePartyRelationship(this.relationship.id, this.relationship)
      .subscribe(
        (data: any) => {
          this.prevRequests = [];
          if (data.success) {
            this.responseHelperService.success(
              this.isEdit
                ? 'Party relationship successfully updated'
                : 'Party relationship successfully created',
              true
            );
            this.successEvent.emit();
          }
        },
        (data: any) => {
          this.errorMsg = data.error.error;
          this.prevRequests = [];
          this.toastr.error('Error occurred!');
        }
      );
  }

  ok(): void {
    this.relationship.partyIdFrom = this.personId;
    this.relationship.partyIdTo = parseInt(this.result);
    this.prevRequests = [];
    this.errorMsg = '';

    this.createPartyRoleIfNotExist(this.partyRoleTypeFromId, true);
    this.createPartyRoleIfNotExist(this.partyRoleTypeToId, false);

    if (this.prevRequests.length > 0) {
      forkJoin(this.prevRequests).subscribe({
        next: (res) => {
          this.createOrUpdatePartyRelationship();
        },
        error: (err) => {
          // Handle error here
          this.prevRequests = [];
          this.getPartyRoleByPartyId(this.relationship.partyIdFrom);
          this.getPartyRoleByPartyId(this.relationship.partyIdTo, false);
          this.toastr.error('Error occurred!1');
        },
      });
    } else {
      this.createOrUpdatePartyRelationship();
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  setRelationshipType(id: number): void {
    for (let type of this.relationshipsTypes) {
      if (+id === type.id) {
        this.relationship.partyRelationshipTypeId = type.id;
      }
    }
  }

  setPartyRoleType(id: number, isFrom: boolean = true): void {
    for (let type of this.partyRoleTypes) {
      if (+id === type.id) {
        if (isFrom) {
          this.partyRoleTypeFromId = type.id;
        } else {
          this.partyRoleTypeToId = type.id;
        }
      }
    }
  }

  setRelatedEntityType(): void {
    this.searchResults = [];
    this.results = [];
    this.result = null;
  }

  doSearchRelatedEntity = function () {
    this.checkedItems = [];
    if (!this.searchEntity) {
      this.searchEntity = '';
    }
    if (this.select.selectedSearch === 'MEMBER') {
      this.membersService
        .getMembers(this.searchEntity && { search: this.searchEntity })
        .subscribe((data: any) => {
          if (data.success) {
            this.searchResults = parseResponse(data);
            $('#select-all-cb').prop('checked', false);
          }
        });
    } else if (this.select.selectedSearch === 'ORGANIZATION') {
      this.organizationsService
        .getOrganizations(
          this.searchEntity && {
            keyword: this.searchEntity,
          }
        )
        .subscribe((data: any) => {
          if (data.success) {
            this.searchResults = parseResponse(data);
            $('#select-all-cb').prop('checked', false);
          }
        });
    }
  };

  updateSelected = function (code) {
    if (!this.limitToOne) {
      var $selectAllCb;
      $selectAllCb = $('#select-all-cb');
      if (
        this.results.indexOf(code) !== -1 &&
        this.checkedItems.indexOf(code) !== -1
      ) {
        var codeIdx = this.results.indexOf(code);
        this.results.splice(codeIdx, 1);
        if (this.checkedItems.indexOf(code) !== -1) {
          codeIdx = this.checkedItems.indexOf(code);
          this.checkedItems.splice(codeIdx, 1);
        }
        if (this.checkedItems.length === 0) {
          $selectAllCb.prop('checked', false);
        }
      } else if (this.results.indexOf(code) === -1) {
        this.checkedItems.push(code);
        this.results.push(code);
        if (!$selectAllCb.is('checked')) {
          $selectAllCb.prop('checked', true);
        }
      } else {
        this.checkedItems.push(code);
      }
    } else {
      //we are only allowing one id to be selected in this case
      if (this.checkedItems.length > 0) {
        $('.proc-check:checkbox:checked').each(function ($index) {
          if (this.id !== '' || this.id !== null) {
            var id = null;

            var checkboxId = this.id.split('cb-');
            if (checkboxId.length > 0) {
              id = checkboxId[1];
            }

            if (parseInt(id) !== parseInt(code)) {
              $(this).prop('checked', false);
            }
          }
        });
      }
      if (
        this.results.indexOf(code) !== -1 &&
        this.checkedItems.indexOf(code) !== -1
      ) {
        this.checkedItems.splice(0, 1);
        this.results.splice(0, 1);
      } else {
        this.checkedItems.splice(0, 1);
        this.results.splice(0, 1);
        this.checkedItems.push(code);
        this.results.push(code);
      }
    }
    this.result = this.results.join(',');

    this.result && this.getPartyRoleByPartyId(parseInt(this.result), false);
  };
}
