import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { HttpClient } from '@angular/common/http';
import { PartyRelationship } from '../data/model';

@Injectable({
  providedIn: 'root',
})
export class RelationshipsService {
  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) {}

  getPartyRelationshipTypes(params): any {
    return this.http.get(
      this.globalData.baseUrl + 'party-relationship-types',
      this.setParams(params)
    );
  }

  getPartyRoleTypes(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-role-types');
  }

  getPartyRoleById(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-roles/' + id);
  }

  getPartyRoleByPartyId(id: number): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'party-roles/party/' + id
    );
  }

  createOrUpdatePartyRole(id: number, partyRole: any): any {
    if (id) {
      return this.http.put(
        this.globalData.baseUrl + 'party-roles/' + id,
        partyRole
      );
    }
    return this.http.post(this.globalData.baseUrl + 'party-roles', partyRole);
  }

  getPartyRelationships(partyId: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'party-relationships/party/' + partyId,
      this.setParams(params)
    );
  }

  getPartyRelationshipById(partyId: number): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'party-relationships/' + partyId
    );
  }

  createOrUpdatePartyRelationship(
    id: number,
    partyRelationship: PartyRelationship
  ): any {
    if (id) {
      return this.http.put(
        this.globalData.baseUrl + 'party-relationships/' + id,
        partyRelationship
      );
    }
    return this.http.post(
      this.globalData.baseUrl + 'party-relationships',
      partyRelationship
    );
  }

  deletePartyRelationship(id: number): any {
    return this.http.delete(
      this.globalData.baseUrl + 'party-relationships/' + id
    );
  }
}
