<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{ title }}</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{ errorMsg }}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Relationship Type</label>
            <select
              [(ngModel)]="partyRelationshipTypeId"
              (ngModelChange)="setRelationshipType(partyRelationshipTypeId)"
              class="form-control"
              name="partyRelationshipType"
              [disabled]="relationship.id"
              required
            >
              <option
                *ngFor="let type of relationshipsTypes"
                value="{{ type.id }}"
              >
                {{ type.relationshipName }}
              </option>
            </select>
            <div class="mt-sm" [hidden]="relationship.partyRelationshipTypeId">
              <small class="text-danger">Select party relationship type</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Select party role from</label>
            <select
              [(ngModel)]="partyRoleTypeFromId"
              (ngModelChange)="setPartyRoleType(partyRoleTypeFromId)"
              class="form-control"
              name="partyRoleTypeFromId"
              [disabled]="relationship.id"
              required
            >
              <option *ngFor="let type of partyRoleTypes" value="{{ type.id }}">
                {{ type.roleName }}
              </option>
            </select>
            <div class="mt-sm" [hidden]="partyRoleTypeFromId">
              <small class="text-danger">Select party role</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!relationship.id">
        <div class="col-md-4 pull-left">
          <label>Related entity:</label>
        </div>
        <div class="col-md-6 pull-left">
          <label>Name:</label>
        </div>
      </div>
      <div class="row form-group" *ngIf="!relationship.id">
        <div class="col-md-4 pull-left">
          <select
            [(ngModel)]="select.selectedSearch"
            (ngModelChange)="setRelatedEntityType()"
            name="searchFor"
            class="form-control"
            [disabled]="relationship.id"
            required
          >
            <option value="MEMBER">Member</option>
            <option value="ORGANIZATION">Organization</option>
          </select>
        </div>
        <div class="col-md-7 pull-left">
          <input
            type="text"
            name="searchEntity"
            [(ngModel)]="searchEntity"
            class="form-control"
            [disabled]="relationship.id"
            value="{{ searchFor }}"
          />
        </div>
        <div class="col-md-1 pull-right">
          <button
            class="btn btn-dark-blue pull-right"
            type="submit"
            (click)="doSearchRelatedEntity()"
            [disabled]="!select.selectedSearch"
          >
            <fa-icon [icon]="faSearch"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Party Id to:</label>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-12">
          <input
            style="margin-bottom: 16px"
            type="text"
            name="result"
            [(ngModel)]="result"
            class="form-control bg-gray"
            required
            readonly="readonly"
          />
        </div>
      </div>

      <div
        class="form-group"
        style="max-height: 20vh; overflow-y: auto"
        *ngIf="!relationship.id"
      >
        <div
          class="table-results"
          ng-show="searchResults.length > 0"
          style="max-height: 20vh"
        >
          <table class="table table-pointable table-bordered infinite-table">
            <thead>
              <tr>
                <th style="width: 55px"></th>
                <th>Name</th>
                <th>ID</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let result of searchResults">
                <td>
                  <div
                    class="checkbox checkbox-info m-t-none m-b-none pull-left"
                    style="display: inline-block; width: 50%"
                  >
                    <input
                      id="cb-{{ result.id }}"
                      class="proc-check"
                      type="checkbox"
                      (click)="updateSelected(result.id)"
                    />
                    <label for="cb-{{ result.id }}"></label>
                  </div>
                </td>
                <td *ngIf="select.selectedSearch === 'MEMBER'">
                  {{ result.firstName }} {{ result.lastName }}
                </td>
                <td *ngIf="select.selectedSearch === 'ORGANIZATION'">
                  {{ result.name }}
                </td>
                <td>
                  {{ result.id }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Select party role to</label>
            <select
              [(ngModel)]="partyRoleTypeToId"
              (ngModelChange)="setPartyRoleType(partyRoleTypeToId, false)"
              class="form-control"
              name="partyRoleTypeToId"
              [disabled]="relationship.id"
              required
            >
              <option *ngFor="let type of partyRoleTypes" value="{{ type.id }}">
                {{ type.roleName }}
              </option>
            </select>
            <div class="mt-sm" [hidden]="partyRoleTypeToId">
              <small class="text-danger">Select party role</small>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>From Date</label>
            <div class="input-group date">
              <input
                class="form-control cursor-pointer"
                readonly="readonly"
                (click)="fromDate.toggle()"
                name="fromDate"
                ngbDatepicker
                #fromDate="ngbDatepicker"
                [footerTemplate]="footerTemplatefromDate"
                [(ngModel)]="fromDateValue"
                (ngModelChange)="formatDate('fromDate')"
                [maxDate]="maxDate"
                required
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="fromDate.toggle()"
                  type="button"
                >
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Through Date</label>
            <div class="input-group date">
              <input
                class="form-control cursor-pointer"
                readonly="readonly"
                (click)="thruDate.toggle()"
                name="thruDate"
                ngbDatepicker
                #thruDate="ngbDatepicker"
                [footerTemplate]="footerTemplateThruDate"
                [(ngModel)]="thruDateValue"
                (ngModelChange)="formatDate('thruDate')"
                [minDate]="minDate"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary calendar"
                  (click)="thruDate.toggle()"
                  type="button"
                >
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      class="btn btn-dark-blue"
      type="submit"
      [disabled]="f.form.invalid"
      (click)="ok()"
      title="Save"
    >
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button
      class="btn btn-attention"
      type="button"
      (click)="cancel()"
      title="Cancel"
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>

<ng-template #footerTemplatefromDate>
  <div class="width-100 datePickerTemplate">
    <button
      class="buttonDateTemplate"
      (click)="
        clearDateInput('fromDateValue');
        clearDateInput('minDate');
        fromDate.toggle()
      "
    >
      Clear
    </button>
  </div>
</ng-template>

<ng-template #footerTemplateThruDate>
  <div class="width-100 datePickerTemplate">
    <button
      class="buttonDateTemplate"
      (click)="
        clearDateInput('thruDateValue');
        clearDateInput('maxDate');
        thruDate.toggle()
      "
    >
      Clear
    </button>
  </div>
</ng-template>
