import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Filter } from 'src/app/data/class';
import { RelationshipsService } from 'src/app/services/relationships.service';
import { AppDataService } from 'src/app/app-data.service';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PartyRelationship, PartyRoleType } from 'src/app/data/model';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RelationshipModalComponent } from '../relationship-modal/relationship-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-member-relationships-tab',
  templateUrl: './member-relationships-tab.component.html',
  styleUrl: './member-relationships-tab.component.scss',
})
export class MemberRelationshipsTabComponent {
  @Input() personId: number;
  @Input() selectedPersonName: string;

  lock: boolean;
  limit = 20;
  filter = new Filter();
  lengthToCompare: number;
  relationships: PartyRelationship[];
  partyRoleTypes: PartyRoleType[];
  isLoading = false;
  isAdmin = this.globalData.isAdmin;
  isProgramManager = this.globalData.isProgramManager;
  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;

  limitToOne: boolean = true;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public globalData: AppDataService,
    private relationshipsService: RelationshipsService,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService,
    private modalHelper: NgbModal
  ) {}

  ngOnInit(): void {
    this.initNewSearch();
    this.getPartyRoleTypes();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getPartyRelationships(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getPartyRelationships(concat: boolean): void {
    this.isLoading = true;
    this.relationshipsService
      .getPartyRelationships(this.personId, this.filter)
      .subscribe(
        (data: any) => {
          if (concat) {
            this.relationships = this.relationships.concat(data.entity);
          } else {
            this.relationships = data.entity;
          }
          this.lengthToCompare = data.entity.length;
          this.newLengthEvent.emit(this.lengthToCompare);
          this.lengthToCompare >= this.limit &&
            (this.filter.offset = this.filter.offset + this.limit);
          const totalChilds = data.entity.reduce(
            (totalChilds: number, currentElement: any) =>
              (totalChilds += (currentElement.relationshipDTOList || [])
                .length),
            0
          );
          this.lock = totalChilds < this.limit;
          this.isLoading = false;
        },
        () => {
          this.lock = false;
          this.isLoading = false;
          this.toastr.error('Error occured!');
        }
      );
  }

  getPartyRoleTypes(): void {
    this.isLoading = true;
    this.relationshipsService.getPartyRoleTypes().subscribe(
      (data: any) => {
        this.partyRoleTypes = data.entity;
      },
      () => {
        this.lock = false;
        this.isLoading = false;
        this.toastr.error('Error occured!');
      }
    );
  }

  relationshipModal(id?: number): void {
    let relationshipsTypes: any = [];
    const partyRelationshipTypesFilter: any = {};
    let relationship = {};

    this.relationshipsService
      .getPartyRelationshipTypes(partyRelationshipTypesFilter)
      .subscribe((data: any) => {
        relationshipsTypes = parseResponse(data);
        if (id) {
          this.relationshipsService
            .getPartyRelationshipById(id)
            .subscribe((inst: any) => {
              if (inst.success) {
                relationship = inst.entity;
                const instance = this.modalHelper.open(
                  RelationshipModalComponent
                );
                instance.componentInstance.relationships = this.relationships;
                instance.componentInstance.relationship = relationship;
                instance.componentInstance.partyRoleTypes = this.partyRoleTypes;
                instance.componentInstance.relationshipsTypes =
                  relationshipsTypes;
                instance.componentInstance.personId = this.personId;
                instance.componentInstance.limitToOne = this.limitToOne;
                instance.componentInstance.successEvent.subscribe(() => {
                  this.getPartyRelationships(false);
                  instance.close();
                });
              }
            });
        } else {
          const instance = this.modalHelper.open(RelationshipModalComponent);
          instance.componentInstance.relationships = this.relationships;
          instance.componentInstance.relationshipsTypes = relationshipsTypes;
          instance.componentInstance.partyRoleTypes = this.partyRoleTypes;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.limitToOne = this.limitToOne;
          instance.componentInstance.successEvent.subscribe(() => {
            this.getPartyRelationships(false);
            instance.close();
          });
        }
      });
  }

  deletePartyRelationship(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text =
      'Delete party relationship with id: ' + id + '?';
    instance.componentInstance.header = 'Delete party relationship?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.relationshipsService.deletePartyRelationship(id).subscribe(
        (data: any) => {
          this.responseHelperService.success(
            'Relationship successfully removed',
            false
          );
          this.getPartyRelationships(false);
          instance.close();
        },
        (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        }
      );
    });
  }
}
