<div class="panel-body">
  <div class="panel-body">
    <div class="row">
      <div *ngIf="isProgramManager">
        <div class="row">
          <div class="col-md-12">
            <div class="ibox-tools form-group">
              <button
                class="btn btn-primary btn-sm"
                (click)="relationshipModal()"
                [hidden]="!isProgramManager"
              >
                <fa-icon [icon]="faPlus"></fa-icon> New relationship
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th>Relationship Type</th>
              <th>Party Name To</th>
              <th>Role From</th>
              <th>Role To</th>
              <th>From Date</th>
              <th>To Date</th>
              <th *ngIf="isProgramManager">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let relationship of relationships">
              <tr *ngFor="let child of relationship.relationshipDTOList">
                <td>{{ relationship.relationshipName }}</td>
                <td title="{{ relationship.id }}">
                  {{ child.name || child.partyNameTo }}
                </td>
                <td>{{ child.fromRoleName }}</td>
                <td>{{ child.toRoleName }}</td>
                <td>{{ child.fromDate }}</td>
                <td>{{ child.thruDate }}</td>
                <td>
                  <button
                    *ngIf="isProgramManager"
                    type="button"
                    class="btn btn-warning btn-sm"
                    (click)="relationshipModal(child.id)"
                  >
                    <fa-icon [icon]="faEdit"></fa-icon>
                  </button>
                  <button
                    *ngIf="isAdmin"
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="deletePartyRelationship(child.id)"
                  >
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </td>
              </tr>
            </ng-container>

            <tr
              app-is-there-more
              [numColumns]="6"
              [newLengthEvent]="newLengthEvent"
            ></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getPartyRelationships(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      >
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
